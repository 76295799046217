import {Grid, Text} from '@primer/react-brand'

import IdeList from './IdeList'

export default function SmallIdeSection() {
  return (
    <Grid className="lp-Section-container--centerUntilMedium lp-Grid--noRowGap text-center position-relative my-8">
      <Grid.Column span={12}>
        <Text as="div" size="200" variant="muted" weight="medium" style={{marginBottom: 'var(--base-size-24)'}}>
          GitHub Copilot is available on your favorite platforms:
        </Text>

        <IdeList type="small" location="pricing-cards" />
      </Grid.Column>
    </Grid>
  )
}

try{ SmallIdeSection.displayName ||= 'SmallIdeSection' } catch {}