import {useContext} from 'react'

import {Grid, PricingOptions} from '@primer/react-brand'

import {PlanTypeContext, PlanTypes} from '../context/PlanTypeContext'

type Props = {
  copilotBusinessContactSalesPath: string
  copilotEnterpriseContactSalesPath: string
  copilotEnterpriseSignupPath: string
  copilotForBusinessSignupPath: string
  copilotProSignupPath: string
}

export default function PricingPlans(props: Props) {
  const {
    copilotBusinessContactSalesPath,
    copilotEnterpriseSignupPath,
    copilotForBusinessSignupPath,
    copilotProSignupPath,
  } = props
  const {planType} = useContext(PlanTypeContext)

  return (
    <Grid className="lp-Section-container--centerUntilMedium position-relative mt-0 mb-8">
      <Grid.Column span={12} className="position-relative">
        {planType === PlanTypes.Individual ? (
          <PricingOptions>
            <PricingOptions.Item>
              <PricingOptions.Heading>Free</PricingOptions.Heading>
              <PricingOptions.Description>A fast way to get started with GitHub Copilot.</PricingOptions.Description>
              <PricingOptions.Price>0</PricingOptions.Price>
              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListItem>
                  Access to agent mode, Claude 3.5 Sonnet, GPT-4o, and more
                </PricingOptions.FeatureListItem>
                <PricingOptions.FeatureListItem>2,000 completions per month</PricingOptions.FeatureListItem>
                <PricingOptions.FeatureListItem>50 chats or premium requests per month</PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>
              <PricingOptions.PrimaryAction as="a" href="#">
                Get started
              </PricingOptions.PrimaryAction>
              <PricingOptions.SecondaryAction as="a" href="#">
                Open in VS Code
              </PricingOptions.SecondaryAction>
            </PricingOptions.Item>

            <PricingOptions.Item>
              <PricingOptions.Label>Most popular</PricingOptions.Label>
              <PricingOptions.Heading>Pro</PricingOptions.Heading>
              <PricingOptions.Description>
                Unlimited completions and chat with access to more models.
              </PricingOptions.Description>
              <PricingOptions.Price currencySymbol="$" trailingText="per month or $100 per year" originalPrice="19">
                10
              </PricingOptions.Price>
              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListHeading>Everything in Free and:</PricingOptions.FeatureListHeading>
                <PricingOptions.FeatureListItem>
                  Access to code review, Claude 3.7 Sonnet, o1, and more
                </PricingOptions.FeatureListItem>
                <PricingOptions.FeatureListItem>
                  Unlimited completions and chats with base model
                </PricingOptions.FeatureListItem>
                <PricingOptions.FeatureListItem>
                  20x more premium requests than Free, with the option to buy more
                </PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>
              <PricingOptions.PrimaryAction as="a" href={copilotProSignupPath}>
                Try for 30 days free
              </PricingOptions.PrimaryAction>

              <PricingOptions.Footnote>
                Free for verified students, teachers, and maintainers of popular open source projects. Learn more
              </PricingOptions.Footnote>
            </PricingOptions.Item>

            <PricingOptions.Item>
              <PricingOptions.Heading>Pro+</PricingOptions.Heading>
              <PricingOptions.Description>Maximum flexibility and model choice.</PricingOptions.Description>
              <PricingOptions.Price>39</PricingOptions.Price>
              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListHeading>Everything in Pro and:</PricingOptions.FeatureListHeading>
                <PricingOptions.FeatureListItem>Access to all models, including GPT-4.5</PricingOptions.FeatureListItem>
                <PricingOptions.FeatureListItem>
                  50x more premium requests than Free, with the option to buy more
                </PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>
              <PricingOptions.PrimaryAction as="a" href="/buy">
                Get started
              </PricingOptions.PrimaryAction>
            </PricingOptions.Item>
          </PricingOptions>
        ) : (
          <PricingOptions>
            <PricingOptions.Item>
              <PricingOptions.Heading>Business</PricingOptions.Heading>
              <PricingOptions.Description>Accelerate workflows with GitHub Copilot.</PricingOptions.Description>
              <PricingOptions.Price currencySymbol="$" trailingText="per user / month">
                19
              </PricingOptions.Price>
              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListItem>
                  Access to agent mode, code review, Claude 3.5/3.7 Sonnet, o1, and more
                </PricingOptions.FeatureListItem>
                <PricingOptions.FeatureListItem>
                  Unlimited completions and chats with base model
                </PricingOptions.FeatureListItem>
                <PricingOptions.FeatureListItem>
                  1,000 premium requests per user, with the option to buy more
                </PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>
              <PricingOptions.PrimaryAction as="a" href={copilotForBusinessSignupPath}>
                Get started
              </PricingOptions.PrimaryAction>
              <PricingOptions.SecondaryAction as="a" href={copilotBusinessContactSalesPath}>
                Contact sales
              </PricingOptions.SecondaryAction>
            </PricingOptions.Item>

            <PricingOptions.Item>
              <PricingOptions.Label>Best value</PricingOptions.Label>
              <PricingOptions.Heading>Enterprise</PricingOptions.Heading>
              <PricingOptions.Description>
                Scale with AI agents and comprehensive model access.
              </PricingOptions.Description>
              <PricingOptions.Price currencySymbol="$" trailingText="per user / month">
                39
              </PricingOptions.Price>
              <PricingOptions.FeatureList>
                <PricingOptions.FeatureListHeading>Everything in Business and:</PricingOptions.FeatureListHeading>
                <PricingOptions.FeatureListItem>Access to all models, including GPT-4.5</PricingOptions.FeatureListItem>
                <PricingOptions.FeatureListItem>
                  2.5x more premium requests per user than Business, with the option to buy more
                </PricingOptions.FeatureListItem>
              </PricingOptions.FeatureList>
              <PricingOptions.PrimaryAction as="a" href={copilotEnterpriseSignupPath}>
                Get started
              </PricingOptions.PrimaryAction>
              <PricingOptions.SecondaryAction as="a" href="#">
                Contact sales
              </PricingOptions.SecondaryAction>
            </PricingOptions.Item>
          </PricingOptions>
        )}
      </Grid.Column>
    </Grid>
  )
}

try{ PricingPlans.displayName ||= 'PricingPlans' } catch {}