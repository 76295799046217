import {PlanTypes, type PlanType} from '../../context/PlanTypeContext'

import styles from './PlanTypeSegmentedControl.module.css'

interface PlanTypeSegmentedControlProps {
  value: PlanType
  onChange: (value: PlanType) => void
}

export default function PlanTypeSegmentedControl({value, onChange}: PlanTypeSegmentedControlProps) {
  return (
    <div className={styles.container}>
      <button
        className={`${styles.segment} ${value === PlanTypes.Individual ? styles.active : ''}`}
        onClick={() => onChange(PlanTypes.Individual)}
      >
        For individuals
      </button>

      <button
        className={`${styles.segment} ${value === PlanTypes.Business ? styles.active : ''}`}
        onClick={() => onChange(PlanTypes.Business)}
      >
        For businesses
      </button>
    </div>
  )
}

try{ PlanTypeSegmentedControl.displayName ||= 'PlanTypeSegmentedControl' } catch {}